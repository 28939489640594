<template>
  <div class="container">
    <RoleCard theme="student">
      <template #label_img>
        <img
          src="@/assets/images/reg_school.svg"
          alt="иконка" />
      </template>
      <template #label>Приглашение в команду</template>
      <template #body>
        <BaseLoadingBox
          v-if="!success"
          theme="reg"
          :pending="pending">
          <div v-if="['guest', 'unregister', 'mentor'].includes(inviterStatus)">
            <!-- пользователь не авторизован  -->
            <div v-if="inviterStatus === 'guest'">
              <p class="mb-l">
                Для того чтобы принять это приглашение, войдите в&nbsp;учетную
                запись участника Олимпиады
              </p>
              <BaseButton
                tag="a"
                theme="primary"
                :href="`${$store.state.clientApiPrefix}/auth/go?next=${$route.fullPath}`"
                >Войти в учетную запись</BaseButton
              >
            </div>

            <!-- Пользователь не зарегистрирован  -->
            <div v-else-if="inviterStatus === 'unregister'">
              <p class="mb-l">
                Только действующие участники командного этапа Олимпиады могут
                принять это приглашение.
              </p>
              <BaseButton
                tag="a"
                theme="primary"
                :href="`${$store.state.clientApiPrefix}/auth/go?next=/registration`"
                >Зарегистрироваться</BaseButton
              >
            </div>

            <!-- пользователь ментор  -->
            <div v-else-if="inviterStatus === 'mentor'">
              <p class="mb-l">
                Вы&nbsp;не&nbsp;можете принять это приглашение, так как
                являетесь Наставником.
              </p>
              <BaseButton
                tag="router-link"
                theme="primary"
                :to="{ name: 'mentor-index' }"
                >В кабинет наставника</BaseButton
              >
            </div>
          </div>

          <div v-else-if="team && teamTrack">
            <div class="mb-m">
              Вас приглашают вступить в команду &laquo;{{
                team.title
              }}&raquo;<span v-if="profile">
                по профилю: &laquo;{{ profile.title }}&raquo;</span
              >.
            </div>

            <div v-if="teamBuildingStageEnded">
              <p class="mb-l color-error">
                Этап формирования команд по профилю &laquo;{{
                  profile.title
                }}&raquo; уже завершен.
              </p>
              <BaseButton
                tag="router-link"
                theme="primary"
                :to="{ name: 'user-index' }"
                >{{ backToParticipant }}</BaseButton
              >
            </div>
            <div v-else>
              <!-- Пользователь не состоит в профиле  -->
              <div v-if="inviterStatus === 'not_in_profile'">
                <p class="mb-l">
                  Вы&nbsp;не&nbsp;можете принять это приглашение, так как
                  не&nbsp;участвуете в&nbsp;командном этапе в&nbsp;выбранном
                  профиле
                </p>
                <BaseButton
                  tag="router-link"
                  theme="primary"
                  :to="{ name: 'user-index' }"
                  >{{ backToParticipant }}</BaseButton
                >
              </div>

              <!-- Пользователь уже состоит в команде в выбранном профиле  -->
              <div v-else-if="inviterStatus === 'has_team'">
                <p class="mb-l">
                  Вы&nbsp;не&nbsp;можете принять приглашение, так как уже
                  состоите в&nbsp;другой команде. Сперва покиньте текущую
                  команду, а&nbsp;затем пройдите по&nbsp;ссылке-приглашению еще
                  раз.
                </p>
                <BaseButton
                  tag="router-link"
                  theme="primary"
                  :to="{ name: 'user-index' }"
                  >{{ backToParticipant }}</BaseButton
                >
              </div>

              <!-- Пользователь уже состоит в этой команде -->
              <div v-else-if="inviterStatus === 'member'">
                <p class="mb-l">
                  Вы не можете принять приглашение, так как уже состоите в этой
                  команде.
                </p>
                <BaseButton
                  tag="router-link"
                  theme="primary"
                  :to="{ name: 'user-index' }"
                  >{{ backToParticipant }}</BaseButton
                >
              </div>

              <!-- Трек капитана не соотвествует треку юзера -->
              <div v-else-if="inviterStatus === 'track_missmatch'">
                <p class="mb-l">
                  Вы&nbsp;не&nbsp;можете принять это приглашение, так как трек
                  команды ({{ teamTrack.title }}) не соответствует вашему треку
                  ({{ userTrackTitle }}).
                </p>
                <BaseButton
                  tag="router-link"
                  theme="primary"
                  :to="{ name: 'user-index' }"
                  >{{ backToParticipant }}</BaseButton
                >
              </div>

              <!-- Пользователь может принять приглашение -->
              <div
                v-else-if="inviterStatus === 'ok'"
                class="mt-l">
                <BaseButton
                  theme="primary"
                  :disabled="!!error"
                  @click="handleInvite"
                  >Присоединиться к команде</BaseButton
                >&emsp;
                <BaseButton
                  tag="router-link"
                  theme="reg"
                  :to="{ name: 'user-index' }"
                  >{{ backToParticipant }}</BaseButton
                >
              </div>
            </div>
          </div>

          <div v-else-if="team && !teamTrack">
            Не удалось получить трек команды, пожалуйста сообщите нам об этой
            ошибке.
          </div>
          <div v-else>
            Команда с указанным id не найдена. Возможно она была удалена или
            никогда не существовала. Попросите капитана команды отправить ссылку
            повторно
          </div>
          <div
            v-if="error"
            class="form-error mt-m">
            {{ error }}
          </div>
        </BaseLoadingBox>
        <div v-else>
          <p class="mb-l">Поздравляем, теперь вы член команды.</p>
          <BaseButton
            tag="router-link"
            theme="primary"
            :to="{ name: 'user-index' }"
            >{{ backToParticipant }}</BaseButton
          >
        </div>
      </template>
    </RoleCard>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import RoleCard from "@/components/registration/RoleCard.vue";
import { talentRequest, request } from "@/services/api";
import { TEAM_BUILDING_STAGE } from "@/constants";
import dayjs from "@/plugins/dayjs";
/**
 * Экран перехода по ссылке-приглашению в команду
 */
export default {
  name: "InviteTeam",
  components: {
    RoleCard,
  },
  metaInfo() {
    return {
      title: "Приглашение в команду",
    };
  },
  data() {
    return {
      pending: false,
      error: "",
      team: null,
      teamTrack: null,
      success: false,
      backToParticipant: "Вернуться в личный кабинет",
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      isParticipant: "user/isParticipant",
      isMentor: "user/isMentor",
      teamStageList: "participant/teamStageList",
      teamProfileTracks: "participant/teamProfileTracks",
    }),
    ...mapState({
      tracks: (state) => state.tracks,
    }),
    profile() {
      return this.$store.state.profile.profiles[this.$route.params.profile];
    },
    currentStage() {
      return this.teamStageList?.find(
        (n) => n.profile_id === +this.$route.params.profile
      );
    },
    inviterStatus() {
      const { isMentor, isParticipant, user, currentStage } = this;
      if (!user) return "guest";
      if (isMentor) return "mentor";
      if (user && !isParticipant) return "unregister";
      // нет прохода в ком. этап по этому профилю
      if (!currentStage) {
        return "not_in_profile";
      }
      // уже член этой команды
      if (currentStage.team_id === +this.$route.params.team) {
        return "member";
      }
      // уже есть команда в этом профиле
      if (currentStage.team_id) {
        return "has_team";
      }
      // трек юзера не соответствует треку команды
      if (
        this.teamProfileTracks[this.$route.params.profile] !==
        this.teamTrack?.id
      ) {
        return "track_missmatch";
      }
      return "ok";
    },
    userTrackTitle() {
      const id = this.teamProfileTracks[this.$route.params.profile];
      if (!id) {
        return "Не определен";
      }
      return this.tracks[id]?.title;
    },

    teamBuildingStageEnded() {
      // specific schedule stage for profile
      const pScheduleStage = this.$store.state.profile.schedule[
        this.$route.params.profile
      ]?.find((n) => n.stage == TEAM_BUILDING_STAGE);
      // common schedule stage
      const commonStage = this.$store.state.schedule?.find(
        (n) => n.stage == TEAM_BUILDING_STAGE
      );
      const teamBuildingEndDate = pScheduleStage?.end_at || commonStage?.end_at;
      if (!teamBuildingEndDate) return false;
      return dayjs().isAfter(teamBuildingEndDate);
    },
  },
  created() {
    this.init();
  },
  methods: {
    async handleInvite() {
      this.pending = true;
      const { currentStage } = this;
      const { code, team } = this.$route.params;
      try {
        // Инвайтимся в команду в таланте
        // В бек НТО инфа придет по хукам, возможно
        await talentRequest({
          method: "POST",
          params: {
            invite_code: code,
          },
          url: `/api/users/${this.user.talent_id}/teams/${team}/user/`,
          data: {
            user_id: this.user.talent_id,
          },
        });
        this.$store.commit("participant/UPDATE_PROFILE_STAGE", {
          ...currentStage,
          team_id: +team,
        });
        this.success = true;
      } catch (error) {
        this.error = error.message;
      }
      this.pending = false;
    },
    async init() {
      const { user, isParticipant } = this;
      this.pending = true;
      if (!user || !isParticipant) {
        this.pending = false;
        return;
      }
      try {
        // Получение данных о команде
        const { data: team } = await talentRequest({
          method: "get",
          url: `/api/teams/${this.$route.params.team}/`,
        });
        this.team = team;
        const { data } = await request({
          method: "get",
          url: `/team/${this.$route.params.team}`,
        });
        this.teamTrack = data.track;
        // Получение трека команды
        const requests = [
          this.$store.dispatch("profile/getProfiles"),
          this.$store.dispatch("participant/getInfo"),
          this.$store.dispatch("getTracks"),
        ];
        await Promise.all(requests);
      } catch (error) {
        this.error = error.message;
      }

      try {
        await Promise.all([
          this.$store.dispatch("getSchedule"),
          this.$store.dispatch(
            "profile/getProfileSchedule",
            +this.$route.params.profile
          ),
        ]);
      } catch (error) {
        //
      }

      this.pending = false;
    },
  },
};
</script>

<style></style>
